import type { HaloCaches } from '@halo-data/cache';
import { compareXuids, wrapXuid } from '@halo-data/utilities';
import { MatchInfo } from 'halo-infinite-api';
import { DateTime } from 'luxon';
import type { LeaderboardEntry } from './leaderboard-entry';
import { PlayerMatchHistoryStatsSkill } from './player-match-history-stats-skill';
import { skillRankCombined } from './skill-rank-helpers';
import { Observer } from 'rxjs';

export async function fetchFullyLoadedMatch(
  leaderboard:
    | { addLeaderboardEntries: (e: LeaderboardEntry[]) => void }
    | undefined,
  match: { MatchId: string; MatchInfo: MatchInfo },
  users: { xuid: string }[],
  signal: AbortSignal,
  haloCaches: HaloCaches,
  loadUserData: boolean,
  logger$?: Observer<string>
): Promise<PlayerMatchHistoryStatsSkill> {
  const matchStatsPromise = haloCaches.matchStatsCache.get(match.MatchId); // This is causing abort errors for some reason

  const [
    stats,
    skills,
    MapVariant,
    UgcGameVariant,
    playlist,
    playlistVersion,
    userInfoMap,
  ] = await Promise.all([
    matchStatsPromise,
    matchStatsPromise.then((matchStats) => {
      const playersToFetch = matchStats.Players.filter((p) =>
        /^xuid\(\d+\)$/.test(p.PlayerId)
      ).map((p) => ({
        matchId: match.MatchId,
        playerId: p.PlayerId,
      }));
      return Promise.all(
        haloCaches.matchSkillsCache.get(playersToFetch, signal).map((p, i) =>
          p.catch(() => ({
            Id: playersToFetch[i].playerId,
            ResultCode: 1,
            Result: null,
          }))
        )
      );
    }),
    haloCaches.mapCache.get(match.MatchInfo.MapVariant, signal),
    haloCaches.gameVariantCache.get(match.MatchInfo.UgcGameVariant, signal),
    match.MatchInfo.Playlist?.AssetId
      ? haloCaches.playlistCache
          .get(match.MatchInfo.Playlist.AssetId)
          .catch(() => null)
      : null,
    match.MatchInfo.Playlist
      ? haloCaches.playlistVersionCache.get(match.MatchInfo.Playlist, signal)
      : null,
    loadUserData
      ? matchStatsPromise.then((s) =>
          haloCaches.usersCache.get(
            s.Players.filter((p) => /^xuid\(\d+\)$/.test(p.PlayerId)).map((p) =>
              wrapXuid(p.PlayerId)
            ),
            signal
          )
        )
      : new Map<
          string,
          Promise<{
            xuid: string;
            gamertag: string;
          }>
        >(),
  ]);

  if (match.MatchInfo.Playlist?.AssetId) {
    const playlistAssetId = match.MatchInfo.Playlist.AssetId;
    const entryPromises: Promise<LeaderboardEntry | void>[] = [];
    for (const s of skills) {
      if (s.ResultCode === 0 && s.Result != null) {
        const esr = skillRankCombined(s.Result, 'Expected');
        if (esr != null) {
          const userInfo =
            userInfoMap.get(s.Id) ?? haloCaches.usersCache.get(wrapXuid(s.Id));
          entryPromises.push(
            userInfo
              .then((u) => ({
                xuid: s.Id,
                matchDate: DateTime.fromISO(
                  match.MatchInfo.StartTime
                ).toMillis(),
                csr: s.Result.RankRecap.PostMatchCsr.Value,
                playlistAssetId,
                gameVariantAssetId:
                  match.MatchInfo.UgcGameVariant?.AssetId ?? '',
                matchId: match.MatchId,
                gamertag: u.gamertag,
                esr,
              }))
              .catch(() => {
                logger$?.next(
                  'Failed to get user info for leaderboard entry ' + s.Id
                );
              })
          );
        }
      }
    }

    if (entryPromises.length > 0) {
      Promise.all(entryPromises).then((entriesToAdd) => {
        const validEntries = entriesToAdd.filter((e) => !!e);
        logger$?.next(
          `Adding ${validEntries.length} leaderboard entries for match ${match.MatchId}`
        );
        return leaderboard?.addLeaderboardEntries(validEntries);
      });
    }
  }

  const Players = await Promise.all(
    stats.Players.map(async (p) => {
      const userInfo = (await userInfoMap.get(p.PlayerId)) ?? {
        xuid: p.PlayerId,
        gamertag: '',
      };
      const playerSkill = skills?.find(
        (s) => s.ResultCode === 0 && s.Result != null && s.Id === p.PlayerId
      )?.Result;
      if (playerSkill) {
        return { ...p, Skill: playerSkill, ...userInfo };
      } else {
        return { ...p, ...userInfo };
      }
    })
  );

  return {
    ...match,
    MatchInfo: {
      ...match.MatchInfo,
      MapVariant,
      UgcGameVariant,
      Playlist:
        playlist && playlistVersion
          ? {
              ...playlist,
              ...playlistVersion,
            }
          : match.MatchInfo.Playlist,
    },
    Players: await Promise.all(
      (users.length
        ? stats.Players.filter((p) =>
            users.some((u) => compareXuids(u.xuid, p.PlayerId))
          )
        : stats.Players
      ).map(
        async (player) =>
          (await userInfoMap.get(player.PlayerId)) ?? {
            xuid: player.PlayerId,
            gamertag: '',
          }
      )
    ),
    MatchStats: {
      ...stats,
      Players,
      Teams: stats.Teams.map((t) => ({
        ...t,
        Players: Players.filter((p) => p.LastTeamId === t.TeamId),
      })),
    },
  } satisfies PlayerMatchHistoryStatsSkill;
}
