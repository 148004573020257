import { HaloCaches } from '@halo-data/cache';
import { compareXuids, requestPolicy } from '@halo-data/utilities';
import { haloInfiniteClient, xboxClient } from './clients';
import { getLeaderboardTable } from './leaderboard/csr-storage/indexed-db-repository';

const haloCaches = new HaloCaches(
  haloInfiniteClient,
  xboxClient,
  requestPolicy,
  {
    async fetchManyFn(keys) {
      const leaderboardTable = await getLeaderboardTable();
      const entries = await leaderboardTable
        .filter((entry) => keys.includes(entry.xuid))
        .toArray();

      return entries.distinct((e1, e2) => compareXuids(e1.xuid, e2.xuid));
    },
    resultSelector(items, key) {
      return items.find((entry) => entry.xuid === key) ?? null;
    },
  }
);
export const {
  fullUsersCache,
  usersCache,
  matchStatsCache,
  matchSkillsCache,
  playlistCache,
  matchPageCache,
  mapCache,
  gameVariantCache,
  playlistVersionCache,
  mapModePairCache,
} = haloCaches;
export default haloCaches;
