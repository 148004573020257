import {
  abortSignalAll,
  Cache,
  compareXuids,
  DeprecatedCache,
  Fetchers,
  LayerCache,
  MemoryCache,
  NoCache,
  unwrapXuid,
} from '@halo-data/utilities';
import { IPolicy } from 'cockatiel';
import {
  AssetKind,
  AssetKindTypeMap,
  AssetVersionLink,
  GameVariantCategory,
  HaloInfiniteClient,
  MapAsset,
  MapModePairAsset,
  MatchSkill,
  MatchStats,
  PlayerMatchHistory,
  Playlist,
  PlaylistAsset,
  RequestError,
  ResultContainer,
  UgcGameVariantAsset,
  UserInfo,
  XboxClient,
} from 'halo-infinite-api';
import {
  bufferTime,
  filter,
  firstValueFrom,
  map,
  mergeMap,
  share,
  Subject,
} from 'rxjs';
import { CombinedUserCache } from './combined-user-cache';
import { MatchPageCache } from './match-page-cache';

function getGamerpicUrl(baseUrl: URL, size: number) {
  const newUrl = new URL(baseUrl.toString());
  newUrl.searchParams.set('w', size.toString());
  newUrl.searchParams.set('h', size.toString());
  return newUrl.toString();
}

class GamertagMismatchError extends Error {
  constructor(expected: string, actual: string) {
    super(`Expected gamertag ${expected} but got ${actual}`);
  }
}
export class HaloCaches {
  fullUsersCache: Cache<UserInfo, string>;
  usersCache: Cache<{ xuid: string; gamertag: string }, string>;
  matchStatsCache: DeprecatedCache<MatchStats<GameVariantCategory>, string>;
  matchSkillsCache: DeprecatedCache<
    ResultContainer<MatchSkill<0>>,
    {
      matchId: string;
      playerId: string;
    }
  >;
  playlistCache: DeprecatedCache<Playlist, string>;
  matchPageCache: DeprecatedCache<
    PlayerMatchHistory[],
    { start: number; xuid: string; pageSize: number }
  >;
  mapCache: DeprecatedCache<
    MapAsset | AssetVersionLink,
    Omit<AssetVersionLink, 'AssetKind'>
  >;
  gameVariantCache: DeprecatedCache<
    AssetVersionLink | UgcGameVariantAsset,
    Omit<AssetVersionLink, 'AssetKind'>
  >;
  playlistVersionCache: DeprecatedCache<
    AssetVersionLink | PlaylistAsset,
    Omit<AssetVersionLink, 'AssetKind'>
  >;
  mapModePairCache: DeprecatedCache<
    AssetVersionLink | MapModePairAsset,
    Omit<AssetVersionLink, 'AssetKind'>
  >;

  constructor(
    haloInfiniteClient: HaloInfiniteClient,
    xboxClient: XboxClient,
    requestPolicy: IPolicy,
    additionalXuidFetcher?: {
      fetchManyFn: (
        keys: string[],
        signal: AbortSignal
      ) => Promise<{ xuid: string; gamertag: string }[]>;
      resultSelector: (
        items: { xuid: string; gamertag: string }[],
        key: string
      ) => { xuid: string; gamertag: string } | null;
    }
  ) {
    this.fullUsersCache = new LayerCache({
      maxEntries: 1000,
      rollingExpiration: true,
      keyTransformer: (gamertag: string) => gamertag?.toLowerCase(),
      fetchers: [
        {
          fetchOneFn: async (gamertag: string, signal) => {
            const result = await requestPolicy.execute(
              (ctx) =>
                haloInfiniteClient
                  .getUser(gamertag, { signal: ctx.signal })
                  .then((res) => {
                    if (res.gamertag.toLowerCase() !== gamertag.toLowerCase()) {
                      // Halo has returned the wrong gt. Cool, let's hack around that.
                      throw new GamertagMismatchError(gamertag, res.gamertag);
                    }
                    return res;
                  })
                  .catch(async (err) => {
                    if (
                      (err instanceof RequestError &&
                        err.response.status === 429) ||
                      err instanceof GamertagMismatchError
                    ) {
                      const searchResults = await xboxClient.searchUsers(
                        gamertag,
                        {
                          signal,
                        }
                      );
                      const searchResult = searchResults.find(
                        (r) =>
                          r.gamertag.toLowerCase() === gamertag.toLowerCase()
                      );
                      if (!searchResult) {
                        throw new Error(`Failed to find user ${gamertag}`);
                      }
                      const baseUrl = new URL(searchResult.displayPicRaw);
                      return {
                        ...searchResult,
                        gamerpic: {
                          small: getGamerpicUrl(baseUrl, 64),
                          medium: getGamerpicUrl(baseUrl, 208),
                          large: getGamerpicUrl(baseUrl, 424),
                          xlarge: baseUrl.toString(),
                        },
                      };
                    }
                    throw err;
                  }),
              signal
            );

            xuidCache.set(result.xuid, result);
            return result;
          },
        },
      ],
    });

    const xuidInput = new Subject<{ xuid: string; signal: AbortSignal }>();
    const haloInfiniteFetch = (
      requests: { xuid: string; signal: AbortSignal }[]
    ) =>
      haloInfiniteClient.getUsers(requests.map(({ xuid }) => xuid).distinct(), {
        signal: abortSignalAll(requests.map(({ signal }) => signal)),
      });
    const xboxLiveFetch = (requests: { xuid: string; signal: AbortSignal }[]) =>
      xboxClient
        .getProfiles(
          requests.map(({ xuid }) => xuid).distinct(),
          ['Gamertag'],
          {
            signal: abortSignalAll(requests.map(({ signal }) => signal)),
          }
        )
        .then(({ profileUsers }) => {
          return profileUsers.map((profile) => ({
            xuid: profile.id,
            gamertag:
              profile.settings.find((v) => v.id === 'Gamertag')?.value ?? '',
          }));
        });
    let currentFetcher: typeof haloInfiniteFetch | typeof xboxLiveFetch =
      haloInfiniteFetch;
    const xuidBuffer = xuidInput.pipe(
      bufferTime(500, undefined, 8),
      filter((requests) => requests.length > 0),
      mergeMap((requests) =>
        requestPolicy.execute(() =>
          currentFetcher(requests).catch((err) => {
            if (err instanceof RequestError && err.response.status === 429) {
              console.warn('Switching user fetcher due to rate limit...');
              currentFetcher =
                currentFetcher === haloInfiniteFetch
                  ? xboxLiveFetch
                  : haloInfiniteFetch;
              // If this one fails too, let the request policy deal with it.
              return currentFetcher(requests);
            }
            throw err;
          })
        )
      ),
      share()
    );

    const fetchers = [];
    if (additionalXuidFetcher) {
      fetchers.push(additionalXuidFetcher);
    }
    const xuidCache = new LayerCache({
      maxEntries: 1000,
      rollingExpiration: true,
      keyTransformer: (xuid: string) => unwrapXuid(xuid),
      fetchers: [
        ...fetchers,
        {
          fetchOneFn: (xuid: string, signal) => {
            // Listen to the buffer and collect relevant results
            const resultPromise = firstValueFrom(
              xuidBuffer.pipe(
                map((result) => result.find((u) => compareXuids(u.xuid, xuid))),
                filter((result) => result != null)
              )
            );
            xuidInput.next({ xuid, signal });
            return resultPromise;
          },
        },
      ] as Fetchers<
        { xuid: string; gamertag: string },
        string,
        [],
        { xuid: string; gamertag: string }
      >,
    });
    this.usersCache = new CombinedUserCache(this.fullUsersCache, xuidCache);

    this.matchStatsCache = new NoCache({
      fetchOneFn: (matchId: string, signal) =>
        requestPolicy.execute(
          (ctx) =>
            haloInfiniteClient.getMatchStats(matchId, { signal: ctx.signal }),
          signal
        ),
    });
    this.matchSkillsCache = new MemoryCache({
      maxEntries: 8000,
      rollingExpiration: true,
      async fetchManyFn(keys: { matchId: string; playerId: string }[], signal) {
        const matchGroups = keys.groupBy((k) => k.matchId);
        const results = await Promise.all(
          Array.from(matchGroups.entries())
            .filter(([, group]) => group.length > 0)
            .map(async ([matchId, group]) => {
              return {
                matchId,
                skills: await requestPolicy.execute(
                  (ctx) =>
                    haloInfiniteClient.getMatchSkill(
                      matchId,
                      group.map((p) => p.playerId),
                      { signal: ctx.signal }
                    ),
                  signal
                ),
              };
            })
        );
        return results;
      },
      resultSelector: (results, key) => {
        const result = results.find((r) => r.matchId === key.matchId);
        if (!result) {
          throw new Error(`Failed to find match ${key.matchId}`);
        }
        const skill = result.skills.find((s) => s.Id === key.playerId);
        if (!skill) {
          throw new Error(
            `Failed to find skill ${key.matchId}.${key.playerId}`
          );
        }
        return skill;
      },
      keyTransformer: (key) => `${key.matchId}.${key.playerId}`,
    });
    this.playlistCache = new NoCache({
      fetchOneFn: (playlistId: string, signal) =>
        requestPolicy.execute(
          (ctx) =>
            haloInfiniteClient.getPlaylist(playlistId, {
              signal: ctx.signal,
              headers: {
                Accept: 'application/json, text/plain, */*',
                Origin: 'https://www.halowaypoint.com',
              },
            }),
          signal
        ),
    });

    [
      this.mapCache,
      this.gameVariantCache,
      this.playlistVersionCache,
      this.mapModePairCache,
    ] = (
      [
        {
          name: 'map',
          assetKind: AssetKind.Map,
        },
        {
          name: 'game variant',
          assetKind: AssetKind.UgcGameVariant,
        },
        {
          name: 'playlist',
          assetKind: AssetKind.Playlist,
        },
        {
          name: 'map mode pair',
          assetKind: AssetKind.MapModePair,
        },
      ] as const
    ).map(
      <const T extends keyof AssetKindTypeMap>({
        assetKind,
      }: {
        name: string;
        assetKind: T;
      }) =>
        new MemoryCache({
          keyTransformer: (key: Omit<AssetVersionLink, 'AssetKind'>) =>
            `${key.AssetId}.${key.VersionId}`,
          fetchOneFn: (key, signal) =>
            requestPolicy.execute(
              (ctx) =>
                haloInfiniteClient
                  .getSpecificAssetVersion(
                    assetKind,
                    key.AssetId,
                    key.VersionId,
                    {
                      signal: ctx.signal,
                    }
                  )
                  .catch(() => {
                    return { key, AssetKind: assetKind };
                  }),
              signal
            ),
        })
    ) as [
      MemoryCache<
        AssetKindTypeMap[typeof AssetKind.Map] | AssetVersionLink,
        Omit<AssetVersionLink, 'AssetKind'>
      >,
      MemoryCache<
        AssetKindTypeMap[typeof AssetKind.UgcGameVariant] | AssetVersionLink,
        Omit<AssetVersionLink, 'AssetKind'>
      >,
      MemoryCache<
        AssetKindTypeMap[typeof AssetKind.Playlist] | AssetVersionLink,
        Omit<AssetVersionLink, 'AssetKind'>
      >,
      MemoryCache<
        AssetKindTypeMap[typeof AssetKind.MapModePair] | AssetVersionLink,
        Omit<AssetVersionLink, 'AssetKind'>
      >
    ];

    this.matchPageCache = new MatchPageCache(haloInfiniteClient, requestPolicy);
  }
}
